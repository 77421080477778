/*#00800d*/
/*
    GloverSure Foundation Template - CSS in this file is divided into sections: Header, Slideshow etc.

    Styles for other pages like News, Products etc. are split into individual SCSS files in the 'Modules directory'.
*/
/* ===========================] General [======================== */
body,
html {
  background-color: #fff;
  font-family: "Lato", sans-serif;
  color: #5a5a5a;
  font-size: 15px;
  line-height: 1.8; }

h1 {
  font-size: 50px;
  color: #000;
  margin-bottom: 15px;
  font-family: "Playfair Display", serif;
  font-weight: 500; }

h2 {
  font-size: 46px;
  line-height: 1.25;
  color: #000;
  font-family: "Playfair Display", serif;
  margin-bottom: 20px; }

h3 {
  font-size: 32px;
  line-height: 1.25;
  color: #000;
  font-family: "Playfair Display", serif;
  margin-bottom: 20px; }

h4 {
  font-size: 16px;
  text-transform: uppercase;
  color: #02540b;
  font-family: "Lato", sans-serif;
  font-weight: 600;
  letter-spacing: 2px; }

input,
select,
p {
  font-family: "Lato", sans-serif;
  color: #5a5a5a;
  line-height: 1.8; }

.grid-x {
  width: 90%;
  max-width: 1500px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-flow: row wrap; }
  .grid-x.expanded {
    max-width: 100%;
    width: 100%; }
  .grid-x.full {
    max-width: 100%;
    width: 100%; }

.g-recaptcha {
  margin-bottom: 1em; }

.scrollup {
  width: 40px;
  height: 40px;
  position: fixed;
  bottom: 30px;
  right: 30px;
  display: none;
  background-color: #FFF;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  color: #0A0A0A;
  line-height: 40px;
  text-align: center;
  font-size: 20px;
  font-weight: bold; }

#cookie-notice {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #02540b;
  text-align: center;
  padding: 1em;
  color: #fff;
  z-index: 999; }
  #cookie-notice p {
    margin-bottom: 10px;
    font-size: 0.9em; }
  #cookie-notice a {
    background-color: #012204;
    padding: 0.7em 1em;
    display: inline-block;
    font-size: 0.8em;
    border-radius: 2px;
    color: #fff; }

.input-group {
  display: block; }

select {
  appearance: none;
  -webkit-appearance: none;
  height: auto;
  padding: 6px 22px 6px 10px;
  font-size: 14px; }

/* ===========================] End General [======================== */
/* ===========================] Header [======================== */
header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;
  transition: 0.4s;
  padding-top: 30px;
  padding-bottom: 30px; }
  header.header-scrolled {
    background: rgba(0, 0, 0, 0.8);
    z-index: 999999; }
    header.header-scrolled .header-left {
      padding-top: 0.8em;
      padding-bottom: 0.8em; }

.property header {
  background: #2d2d2d; }
  .property header.header-scrolled {
    background: rgba(0, 0, 0, 0.8); }

.header-left {
  padding: 1.3em 0;
  transition: 0.4s; }
  .header-left .logo {
    float: left; }

.header-right .menu li a {
  color: #0e0e0e;
  transition: 0.2s; }
  .header-right .menu li a:hover {
    color: #02540b; }

.header-right .menu-icon::after {
  background: #02540b;
  box-shadow: 0 7px 0 #02540b, 0 14px 0 #02540b; }

img.logo-img {
  max-height: 37px; }

.header-right.cell.medium-1 {
  padding: 0; }

#menu-panel ul {
  text-align: center !important;
  flex-wrap: inherit;
  flex-direction: inherit;
  margin: auto;
  width: auto;
  display: block; }
  #menu-panel ul li {
    display: inline-block;
    text-align: center; }
    #menu-panel ul li.is-dropdown-submenu-parent > a {
      padding-right: 20px; }
    #menu-panel ul li ul.is-dropdown-submenu {
      display: none;
      background: rgba(0, 0, 0, 0.8);
      border: none; }
      #menu-panel ul li ul.is-dropdown-submenu.js-dropdown-active {
        display: block; }
      #menu-panel ul li ul.is-dropdown-submenu li {
        text-align: left; }
        #menu-panel ul li ul.is-dropdown-submenu li a {
          display: block;
          text-align: left; }
    #menu-panel ul li a {
      color: #fff;
      text-align: center;
      margin: 0 auto;
      display: inline-block;
      padding: 12px;
      text-transform: uppercase;
      font-size: 14px; }

.dropdown.menu.large-horizontal > li.is-dropdown-submenu-parent > a::after {
  border: inset 5px;
  border-bottom-width: 0;
  border-top-style: solid;
  border-color: #e5d47f transparent transparent;
  margin-top: -2px; }

.header-left.cell.medium-1 {
  padding: 0; }

.header-social-wrap ul {
  float: right;
  margin: 0; }
  .header-social-wrap ul li {
    display: inline;
    margin: 0 11px; }
    .header-social-wrap ul li:last-of-type {
      margin-right: 0; }
    .header-social-wrap ul li a {
      color: #fff; }

.header-right.cell.medium-2 {
  padding-right: 0 !important; }

.header-right .menu-icon::after {
  background: #ffffff;
  box-shadow: 0 7px 0 #ffffff, 0 14px 0 #ffffff; }

.menu-icon {
  color: #fff; }

img.underline {
  margin-bottom: 18px; }

/* ===========================] End Header [======================== */
/* ===========================] Slideshow [======================== */
#slideshow {
  position: relative;
  z-index: 9; }
  #slideshow .slide {
    background-color: #000; }
    #slideshow .slide img {
      opacity: 0.85;
      height: 65vh;
      object-fit: cover; }
  #slideshow.home .slide img {
    height: 100vh; }
  #slideshow .slider-overlay {
    position: absolute;
    top: 50%;
    left: 70px;
    transform: translateY(-43%);
    right: 70px;
    margin: 0 auto;
    text-align: center;
    z-index: 5; }
    #slideshow .slider-overlay h1 {
      color: #e5d47f;
      font-family: "Lato", sans-serif;
      letter-spacing: 2px;
      text-transform: uppercase;
      line-height: 1.5;
      font-size: 20px;
      font-weight: 600; }
      #slideshow .slider-overlay h1 strong {
        color: #fff;
        font-family: "Playfair Display", serif;
        letter-spacing: 0;
        text-transform: none;
        font-weight: 400;
        font-size: 60px; }
    #slideshow .slider-overlay p {
      color: #fff;
      margin-bottom: 45px;
      font-size: 19px;
      line-height: 1.8; }
    #slideshow .slider-overlay a {
      display: inline-block;
      text-transform: uppercase;
      padding: 10px 35px;
      transition: 0.5s;
      font-weight: 600;
      letter-spacing: 1px;
      border: 2px solid #e5d47f;
      margin: 0 15px;
      text-transform: uppercase;
      color: #fff;
      padding: 15px 45px;
      font-weight: 600;
      letter-spacing: 1px;
      background-color: transparent;
      margin-bottom: 20px; }
      #slideshow .slider-overlay a:hover {
        background: #e5d47f;
        color: #0a0a0a; }
  #slideshow .owl-next,
  #slideshow .owl-prev {
    position: absolute;
    top: 50%;
    transform: translateY(-50%); }
    #slideshow .owl-next i,
    #slideshow .owl-prev i {
      color: #fff;
      padding: 10px; }
  #slideshow .owl-prev {
    left: 30px; }
  #slideshow .owl-next {
    right: 30px; }

@media screen and (max-width: 1025px) {
  #slideshow .slider-overlay {
    margin: 0; }
    #slideshow .slider-overlay .grid-x {
      margin: 0;
      width: 100%; }
      #slideshow .slider-overlay .grid-x h1 strong {
        font-size: 45px;
        font-weight: 600; } }

@media screen and (max-width: 650px) {
  #slideshow .slider-overlay .grid-x h1 strong {
    font-size: 37px; }
  #slideshow .slider-overlay a {
    display: inline-block;
    text-transform: uppercase;
    padding: 10px 35px;
    transition: 0.5s;
    font-weight: 600;
    letter-spacing: 1px;
    border: 2px solid #e5d47f;
    margin: 0 15px 28px;
    text-transform: uppercase;
    color: #fff;
    padding: 13px 39px;
    font-weight: 600;
    letter-spacing: 1px;
    font-size: 12px;
    background-color: transparent; } }

#home-search {
  padding: 75px 0; }
  #home-search h2 {
    font-size: 52px;
    display: block;
    width: 100%;
    text-align: center;
    margin-bottom: 75px; }

/* ===========================] End Slideshow [======================== */
#content a {
  display: inline-block;
  text-transform: uppercase;
  transition: 0.5s;
  color: #02540b;
  padding-bottom: 5px;
  font-weight: 600; }
  #content a:after {
    content: '';
    display: block;
    width: 80%;
    height: 2px;
    background: #02540b;
    transition: 0.5s; }
  #content a:hover:after {
    width: 100%; }

#commercial-properties-home {
  padding: 7.5vw 0; }
  #commercial-properties-home .grid-x {
    width: 98%; }
    #commercial-properties-home .grid-x .text-block {
      padding: 2.5vw 5vw; }
      #commercial-properties-home .grid-x .text-block a {
        display: inline-block;
        text-transform: uppercase;
        padding: 10px 35px;
        transition: 0.5s;
        font-weight: 600;
        letter-spacing: 1px;
        border: 2px solid #02540b;
        color: #02540b; }
        #commercial-properties-home .grid-x .text-block a:hover {
          color: #fff;
          background: #02540b; }
    #commercial-properties-home .grid-x .text-block-two {
      padding: 2.5vw 5vw; }

#lettings-home a {
  display: inline-block;
  text-transform: uppercase;
  padding: 10px 35px;
  transition: 0.5s;
  font-weight: 600;
  letter-spacing: 1px;
  border: 2px solid #02540b;
  color: #02540b; }
  #lettings-home a:hover {
    color: #fff;
    background: #02540b; }

#who-we-are-home {
  padding: 7.5vw 0; }
  #who-we-are-home .text-block {
    padding: 5vw; }
    #who-we-are-home .text-block a {
      display: inline-block;
      text-transform: uppercase;
      padding: 10px 35px;
      transition: 0.5s;
      font-weight: 600;
      letter-spacing: 1px;
      border: 2px solid #02540b;
      color: #02540b; }
      #who-we-are-home .text-block a:hover {
        color: #fff;
        background: #02540b; }

/* ===========================] Media Queries [======================== */
@media screen and (max-width: 1025px) {
  .menu-btn-wrap {
    display: block;
    text-align: right; }
  .mobile-menu-wrap .menu.vertical {
    flex-wrap: wrap; }
    .mobile-menu-wrap .menu.vertical li {
      flex: 0 0 100%; }
  .mobile-menu-wrap .close-button {
    display: block !important; }
  .mobile-menu-wrap {
    position: fixed;
    height: 100%;
    width: 400px;
    right: 0;
    top: 0;
    background: #1d1d1d;
    padding: 5em 2em;
    z-index: 9999;
    transform: translateX(100%);
    -webkit-transition: transform cubic-bezier(1, 0.21, 0, 1.07) 0.45s;
    -moz-transition: transform cubic-bezier(1, 0.21, 0, 1.07) 0.45s;
    transition: transform cubic-bezier(1, 0.21, 0.26, 0.98) 0.45s;
    box-shadow: none; }
    .mobile-menu-wrap ul {
      margin-top: 3em; }
      .mobile-menu-wrap ul li a {
        color: #fff;
        padding: 1em; }
    .mobile-menu-wrap.is-active {
      transform: translateX(0); }
    .mobile-menu-wrap .close-button {
      padding: 0.5em;
      position: absolute;
      top: 1rem;
      right: 1rem;
      line-height: 21px; }
      .mobile-menu-wrap .close-button span {
        color: #fff;
        font-size: 2.5rem;
        font-weight: lighter; }
  #menu-panel ul li a {
    width: 100%;
    text-align: left;
    font-size: 18px; }
  #menu-panel ul li.is-submenu-item a {
    font-size: 17px;
    padding: 12px 24px; }
  .accordion-menu .is-accordion-submenu-parent:not(.has-submenu-toggle) > a::after {
    border-color: #fff transparent transparent; } }

@media screen and (max-width: 640px) {
  .mobile-menu-wrap {
    width: 100%; }
  .mobile-padding {
    padding: 0 6%; } }

.select {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 430px;
  height: 90px; }

.option {
  padding: 15px 30px 9px 29px;
  min-height: 60px;
  display: flex;
  align-items: left;
  background: #f2f2f2;
  border-top: #e8e8e8 solid 1px;
  position: absolute;
  top: 7px;
  width: 100%;
  pointer-events: none;
  order: 2;
  z-index: 1;
  transition: background 0.4s ease-in-out;
  box-sizing: border-box;
  overflow: hidden;
  margin-top: 0;
  white-space: nowrap;
  text-align: left; }

.option:hover {
  background: #fff; }

.select:focus .option {
  position: relative;
  pointer-events: all; }

input {
  opacity: 0;
  position: absolute;
  left: -99999px; }

button,
button:focus,
input,
input:focus,
label:focus,
select,
select:focus,
textarea,
textarea:focus {
  outline: none !important;
  outline: 1px solid #aaa;
  outline-width: 0; }

input:checked + label {
  order: 1;
  z-index: 2;
  background: #f2f2f2;
  border-top: none;
  position: relative;
  text-align: left;
  padding: 0;
  line-height: 56px;
  height: 60px;
  vertical-align: middle;
  padding-left: 29px;
  overflow: hidden;
  border: 0 solid;
  text-transform: uppercase;
  color: #000; }

input:checked + label:after {
  content: '';
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid white;
  position: absolute;
  right: 30px;
  top: calc(50% - 2.5px);
  pointer-events: none;
  z-index: 3; }

input:checked + label:before {
  position: absolute;
  right: 0;
  height: 60px;
  width: 70px;
  content: '';
  background: #02540b; }

section#home-search .select {
  text-align: center;
  margin: auto; }

.new-listing-header-content {
  margin-bottom: 45px; }

.property-image-wrap img {
  display: block;
  width: 100%;
  min-height: 400px;
  height: 400px;
  object-fit: cover; }
  @media screen and (max-width: 1200px) {
    .property-image-wrap img {
      min-height: 260px;
      height: 260px; } }
  @media screen and (max-width: 1025px) {
    .property-image-wrap img {
      min-height: 250px;
      height: 250px; } }

p.latest-property-price {
  font-size: 28px;
  line-height: 1.3;
  color: #000;
  margin-top: 15px;
  margin-bottom: 5px;
  font-family: "Lato", sans-serif;
  font-weight: 600; }

span.inline-property-info {
  color: #02540b;
  font-size: 15px;
  font-weight: 600;
  font-family: "Lato", sans-serif; }
  span.inline-property-info strong {
    font-weight: 600; }

section#latest-properties {
  margin-bottom: 198px; }
  section#latest-properties .latest-properties-slider .owl-nav {
    position: absolute;
    top: -71px; }
    section#latest-properties .latest-properties-slider .owl-nav button i {
      color: #c7c7c7;
      font-size: 30px; }
      @media screen and (max-width: 1025px) {
        section#latest-properties .latest-properties-slider .owl-nav button i {
          display: none; } }
    section#latest-properties .latest-properties-slider .owl-nav i.fa.fa-angle-left {
      margin-right: 28px; }

#content {
  background-color: #f3f3f3;
  position: relative;
  margin: 100px 0 20px;
  padding: 150px 0 120px;
  display: block; }
  #content img {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    max-height: 80vh;
    right: 0; }
  @media screen and (max-width: 1025px) {
    #content {
      margin: 100px 0 0;
      padding: 80px 0; } }

#main-content-wrap {
  padding: 75px 0; }
  #main-content-wrap h2 {
    font-size: 28px; }
  #main-content-wrap h3 {
    margin: 30px 0 10px;
    font-size: 18px; }
  #main-content-wrap table {
    margin-top: 50px; }
    #main-content-wrap table tbody {
      font-weight: 400;
      font-size: 16px; }
      #main-content-wrap table tbody tr {
        background: none; }
      #main-content-wrap table tbody th {
        vertical-align: middle;
        padding: 15px;
        text-align: left;
        background: #2a2a2a;
        border: none; }
        #main-content-wrap table tbody th p {
          margin: 0;
          color: #fff; }
      #main-content-wrap table tbody td {
        vertical-align: top;
        padding: 15px; }
      #main-content-wrap table tbody td p,
      #main-content-wrap table tbody th p {
        margin-bottom: 10px; }
        #main-content-wrap table tbody td p:last-of-type,
        #main-content-wrap table tbody th p:last-of-type {
          margin-bottom: 0; }
    #main-content-wrap table tbody tr:nth-child(even) {
      background: none; }

@media screen and (max-width: 1200px) and (min-width: 1025px) {
  #content img {
    max-height: 50vh; } }

a.button {
  border: 2px solid #02540b;
  color: #02540b;
  background: transparent; }

@media screen and (max-width: 1025px) {
  #content img {
    display: none; }
  section#latest-properties {
    margin-bottom: 98px; } }

@media screen and (max-width: 640px) {
  .image-block p,
  .image-block-left p {
    margin: 0; }
  #commercial-properties-home {
    padding: 0; }
  .eq-height-cols {
    margin: 7% 0 10%; }
    .eq-height-cols h2 {
      font-size: 32px; }
  #commercial-properties-home .grid-x .text-block,
  #commercial-properties-home .grid-x .text-block-left {
    padding: 5vw 3vw 7vw; }
  a.button {
    padding: 13px 39px;
    font-weight: 600;
    letter-spacing: 1px;
    font-size: 12px;
    line-height: 14px; } }

@media screen and (min-width: 1025px) {
  section#lettings-home p {
    margin-right: 85px;
    margin-bottom: 55px; } }

@media screen and (min-width: 640px) {
  img.team-img {
    min-height: 590px;
    object-fit: cover;
    object-position: center; } }

@media screen and (max-width: 640px) {
  img.team-img {
    position: relative;
    height: 330px;
    width: 100%;
    object-fit: cover;
    object-position: center; }
  .lettings-img-1 {
    height: 330px;
    width: 100%;
    object-fit: cover;
    object-position: center; }
  .lettings-img-2 {
    display: none; }
  section#lettings-home .grid-x {
    width: 98%; }
    section#lettings-home .grid-x .small-order-2 {
      margin: 7% 0 10%;
      padding: 5vw 5vw 7vw; }
  #who-we-are-home {
    padding: 0; }
    #who-we-are-home .grid-x {
      width: 98%; }
      #who-we-are-home .grid-x .eq-height-cols {
        margin: 0; }
      #who-we-are-home .grid-x .text-block {
        margin: 7% 0 10%;
        padding: 5vw 5vw 7vw; }
  #reviews-section .title-section {
    margin-bottom: 10px; } }

#reviews-section .title-section {
  width: 90%;
  max-width: 1500px;
  margin: auto;
  text-align: center;
  margin-bottom: 50px; }
  #reviews-section .title-section h3 {
    color: #02540b;
    font-family: "Lato", sans-serif;
    font-size: 15px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 2px; }

.review-slider .eq-height-cols {
  background-color: #f7f7f7;
  text-align: center;
  font-family: "Lato", sans-serif;
  color: #2a2a2a;
  position: relative; }
  .review-slider .eq-height-cols:after {
    content: '';
    position: absolute;
    bottom: -31px;
    left: 50%;
    width: 27px;
    height: 32px;
    transform: translateX(-50%);
    background-repeat: no-repeat; }
  .review-slider .eq-height-cols p {
    padding: 70px 20px; }

.review-slider .review-header {
  text-align: center;
  margin: 48px 0 0;
  font-family: "Lato", sans-serif;
  color: #2a2a2a; }
  .review-slider .review-header h4,
  .review-slider .review-header p.review-name {
    color: #2a2a2a;
    font-size: 18px;
    margin-bottom: 0;
    line-height: 18px;
    font-weight: 500;
    text-transform: none;
    letter-spacing: 0; }
  .review-slider .review-header h5,
  .review-slider .review-header p.review-place {
    color: #2a2a2a;
    font-size: 14px;
    margin-top: 5px; }

section#reviews-section {
  margin-bottom: 100px; }

footer {
  background-color: #2a2a2a;
  color: #fff;
  padding: 50px 0 10px; }
  footer img.footer-logo-img {
    max-height: 37px; }
  footer .tds img {
    background: #fff; }
  footer a.rent img {
    height: 100px;
    vertical-align: middle; }
    @media (max-width: 640px) {
      footer a.rent img {
        height: 75px;
        margin-left: 15px; } }
  footer ul {
    margin: 0;
    float: right;
    margin-top: 21px; }
    footer ul li {
      display: inline;
      padding: 0 10px; }
      footer ul li:last-of-type {
        padding-right: 0; }
      footer ul li a {
        color: inherit;
        text-transform: uppercase;
        font-size: 14px; }
        footer ul li a:hover {
          color: #fafafa; }

.footer-icons {
  margin: 15px auto; }
  .footer-icons img {
    margin: 10px;
    height: 55px; }
    @media screen and (max-width: 650px) {
      .footer-icons img {
        margin: 7px;
        height: 40px; } }

.footer-phone {
  margin-top: 15px; }
  .footer-phone span {
    font-size: 14px;
    font-family: "Lato", sans-serif; }
    .footer-phone span a {
      color: #fff; }
      .footer-phone span a:hover {
        color: #fff; }
      .footer-phone span a i.fas.fa-phone {
        margin-right: 10px; }

.footer-social ul li {
  padding: 0 9px; }

.footer-text {
  margin-top: 26px;
  font-size: 12px;
  color: #868686;
  font-family: "Lato", sans-serif; }
  .footer-text a {
    color: inherit; }

.footer-text-right {
  float: right; }

header.header-content {
  background-color: #1f1f1f; }
  header.header-content.header-scrolled {
    background-color: rgba(0, 0, 0, 0.8); }

#property {
  margin-top: 97px; }

.property-top {
  background-color: #2d2d2d;
  color: #fff;
  margin-bottom: 10px;
  padding: 40px 0 50px; }
  .property-top .property-title-h {
    color: #fff;
    font-size: 40px;
    line-height: 1;
    margin-bottom: 0; }
    @media screen and (max-width: 800px) {
      .property-top .property-title-h {
        font-size: 30px; } }
  .property-top .property-tilte-sub-h {
    font-size: 16px;
    color: #fff;
    margin: 15px 0 0; }
  .property-top .display-price {
    margin: 0;
    color: #fff;
    font-size: 32px;
    font-family: "Playfair Display", serif;
    text-transform: uppercase; }
    @media screen and (max-width: 1000px) {
      .property-top .display-price {
        font-size: 25px; } }
    @media screen and (max-width: 640px) {
      .property-top .display-price {
        font-size: 20px; } }
    .property-top .display-price .display-price-inner {
      display: block; }
    .property-top .display-price .sale-status {
      font-size: 16px;
      display: inline-block;
      background: #02540b;
      padding: 10px 30px; }
  .property-top .property-features {
    list-style: none;
    margin: 35px 0 0;
    padding: 0; }
    @media screen and (max-width: 640px) {
      .property-top .property-features {
        margin: 15px 0 0; } }
    .property-top .property-features li {
      list-style: none;
      margin: 0;
      margin-left: 20px;
      padding: 0;
      display: inline-block;
      font-family: "Playfair Display", serif;
      font-size: 16px; }
      @media screen and (max-width: 1000px) {
        .property-top .property-features li {
          margin: 0;
          width: 45%; } }
      @media screen and (max-width: 640px) {
        .property-top .property-features li {
          margin: 0 0 5px;
          width: 100%;
          font-size: 15px; } }
      .property-top .property-features li:first-of-type {
        margin-left: 0; }

.popup-gallery {
  margin-bottom: 50px; }
  .popup-gallery .main {
    float: left;
    width: 50%; }
    @media screen and (max-width: 640px) {
      .popup-gallery .main {
        width: 100%; } }
    .popup-gallery .main .image {
      width: 100%;
      float: left;
      height: 550px; }
      @media screen and (max-width: 1000px) {
        .popup-gallery .main .image {
          height: 30vh; } }
      @media screen and (max-width: 640px) {
        .popup-gallery .main .image {
          height: auto; } }
  .popup-gallery .others {
    float: left;
    width: 50%;
    height: 550px;
    overflow: auto; }
    @media screen and (max-width: 1000px) {
      .popup-gallery .others {
        height: 30vh; } }
    @media screen and (max-width: 640px) {
      .popup-gallery .others {
        display: none; } }
    .popup-gallery .others::-webkit-scrollbar {
      width: 8px; }
    .popup-gallery .others::-webkit-scrollbar-track {
      background-color: #cecece; }
    .popup-gallery .others::-webkit-scrollbar-thumb {
      background-color: #02540b; }
    .popup-gallery .others .image {
      width: 50%;
      float: left;
      height: 275px; }
      @media screen and (max-width: 1000px) {
        .popup-gallery .others .image {
          height: 15vh; } }
  .popup-gallery:after {
    content: "";
    display: table;
    clear: both; }
  .popup-gallery a {
    display: block; }
  .popup-gallery img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border: 3px solid #fff; }

.mfp-arrow i {
  display: none; }

.property-left-content .display-price {
  background-color: #004d08;
  color: #fff;
  display: inline-block;
  padding: 10px 40px;
  font-size: 16px;
  font-family: "Lato", sans-serif;
  font-weight: 600;
  letter-spacing: 2px;
  border-radius: 3px;
  line-height: 26px; }

.property-left-content .sale-status {
  background-color: #004d08;
  color: #fff;
  display: inline-block;
  padding: 10px 40px;
  font-size: 16px;
  font-family: "Lato", sans-serif;
  font-weight: 600;
  letter-spacing: 2px;
  border-radius: 3px;
  line-height: 26px; }

.property-left-content .property-title-h {
  font-family: "Playfair Display", serif;
  font-weight: 700;
  font-size: 40px;
  margin-bottom: 15px;
  line-height: 1.4;
  color: #2a2a2a; }

.property-left-content .property-tilte-sub-h {
  margin-bottom: 15px;
  font-size: 12px;
  text-transform: uppercase;
  color: #02540b;
  font-family: "Lato", sans-serif;
  font-weight: 900;
  letter-spacing: 2px; }

.right-content-property {
  padding-left: 50px; }
  @media screen and (max-width: 640px) {
    .right-content-property {
      padding-left: 0; } }
  .right-content-property .right-content-top {
    padding: 20px 25px;
    border: 1px solid #f7f7f7;
    text-align: center; }

.property-main p {
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 15px; }

.property-main h2 {
  font-size: 22px;
  margin: 35px 0 10px;
  line-height: 1.65; }

.property-main h3 {
  font-size: 18px;
  margin: 35px 0 10px;
  line-height: 1.65; }

.property-main h4 {
  font-size: 16px;
  margin: 35px 0 10px;
  line-height: 1.65; }

.tabs {
  border: 1px solid #f7f7f7; }

.tabs-title > a {
  display: block;
  padding: 20px 50px;
  font-size: 14px;
  line-height: 1;
  color: #000;
  text-transform: uppercase;
  font-weight: 600;
  font-family: "Lato", sans-serif;
  letter-spacing: 1px;
  outline: none; }
  .tabs-title > a:hover {
    color: #004d08; }

.tabs-title > a[aria-selected='true'] {
  color: #000;
  background-color: #f7f7f7;
  text-align: center;
  font-family: "Lato", sans-serif;
  position: relative;
  outline: none; }

.tabs-content {
  border: 1px solid #f7f7f7;
  border-top: 0;
  background: #fefefe;
  transition: all 0.5s ease;
  font-family: "Lato", sans-serif;
  color: #5a5a5a;
  font-size: 14px;
  font-weight: 600;
  line-height: 26px; }

.tabs-panel {
  display: none;
  padding: 24px; }

.right-content-top {
  position: relative; }
  .right-content-top .interested {
    display: block;
    padding: 10px 0;
    font-size: 18px;
    font-family: "Lato", sans-serif;
    font-weight: 600;
    letter-spacing: 1px;
    border-radius: 3px;
    line-height: 26px; }
  .right-content-top .make-offer-a,
  .right-content-top .message-seller-a,
  .right-content-top .request-viewing-a {
    font-size: 16px;
    font-family: "Lato", sans-serif;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 26px;
    display: block;
    text-align: center;
    max-width: 260px;
    background-color: #f2f2f2;
    color: #919090;
    margin-bottom: 12px;
    border-radius: 2px;
    padding: 12px 10px;
    transition: 0.3s all ease;
    margin: 0 auto 15px; }
    .right-content-top .make-offer-a i,
    .right-content-top .message-seller-a i,
    .right-content-top .request-viewing-a i {
      margin-right: 4px; }
    .right-content-top .make-offer-a:hover,
    .right-content-top .message-seller-a:hover,
    .right-content-top .request-viewing-a:hover {
      background-color: #02540b;
      color: #fff; }
  .right-content-top p a {
    display: block; }
  .right-content-top .request-viewing-a {
    background-color: #02540b;
    color: #fff; }
    .right-content-top .request-viewing-a:hover {
      background-color: #004d08; }

.right-content-mid {
  position: relative;
  margin-top: 10px;
  display: inline-block; }
  .right-content-mid .need-help-a {
    padding: 10px 0;
    letter-spacing: 0;
    border-radius: 3px;
    display: block;
    font-weight: 700;
    font-size: 16px;
    margin-bottom: 0;
    line-height: 1.4;
    color: #2a2a2a;
    font-family: "Playfair Display", serif; }
  .right-content-mid .need-help-sub-a {
    display: block;
    font-size: 14px;
    font-family: "Lato", sans-serif;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 16px;
    margin-bottom: 30px; }
  .right-content-mid a {
    display: block;
    font-size: 14px;
    font-family: "Lato", sans-serif;
    font-weight: 600;
    letter-spacing: 1px;
    line-height: 16px;
    margin-bottom: 10px;
    color: #02540b;
    font-weight: 900; }
    .right-content-mid a:hover {
      color: #004d08; }

a.back-to-search {
  display: block;
  font-size: 13px;
  font-family: "Lato", sans-serif;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 16px;
  margin-bottom: 30px;
  color: #02540b;
  font-style: italic; }

@media screen and (max-width: 1025px) {
  .right-content-mid .need-help-a,
  .right-content-top .interested {
    font-size: 15px;
    letter-spacing: 1px; }
  .property-left-content .display-price,
  .property-left-content .sale-status {
    padding: 10px 20px;
    font-size: 14px;
    letter-spacing: 1px; } }

@media screen and (max-width: 1300px) {
  footer .grid-x .cell.auto {
    min-width: 100% !important;
    text-align: left; }
    footer .grid-x .cell.auto .footer-menu ul {
      float: left; }
      footer .grid-x .cell.auto .footer-menu ul li:first-of-type {
        padding-left: 0; } }

#cookie-notice p {
  color: #fff; }

.review-slider .owl-dots {
  position: absolute;
  bottom: -35px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  text-align: center; }
  .review-slider .owl-dots button.owl-dot {
    background: #02540b;
    color: beige;
    border: none;
    padding: 0 !important;
    font: 20px;
    border: 0 solid;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    opacity: 0.2;
    margin: 0 3px; }
    .review-slider .owl-dots button.owl-dot.active {
      opacity: 1;
      width: 16px;
      height: 16px; }

@media screen and (max-width: 1200px) {
  .listing-item.align-middle {
    align-items: start; }
  .listing-buttons-wrap .button {
    border: 2px solid #02540b;
    color: #ffffff;
    background: #02540b;
    border-radius: 2px;
    margin-right: 3px;
    padding: 0.55em 0.5em;
    font-size: 13px; } }

.listings-pager {
  text-align: center;
  margin-top: 50px; }
  .listings-pager .page {
    display: inline;
    margin: 0 10px; }
    .listings-pager .page a {
      color: #2a2a2a;
      font-family: "Lato", sans-serif;
      font-weight: 700;
      opacity: 0.6;
      transition: all 0.3s ease; }
      .listings-pager .page a.is-active {
        color: #02540b;
        opacity: 1; }
      .listings-pager .page a:hover {
        color: #02540b;
        opacity: 1; }

input {
  opacity: 1;
  position: relative;
  left: 0; }

@media screen and (max-width: 1025px) {
  .tablet-align-justify-left {
    margin-left: 0 !important; }
  .tablet-align-justify-right {
    margin-right: 0 !important; }
  .tablet-grid-x {
    width: 100%; }
  .tablet-width-full {
    width: 100% !important; }
  .sidebar-help .right-content-mid {
    margin-top: 14px; }
  .find-properties-button .button,
  .sidebar-help .make-offer-a,
  .sidebar-help .message-seller-a,
  .sidebar-help .request-viewing-a {
    font-size: 14px !important;
    padding: 12px 0 !important; }
  .listing-left-wrap {
    margin-right: 0 !important;
    width: 100%;
    margin-left: 0 !important;
    padding: 0 !important; }
    .listing-left-wrap .listing-property-thumb-img {
      width: 100%;
      display: block;
      padding: 0;
      margin: 0 !important;
      min-width: 100%; }
  .listing-item .small-12.large-6.listing-desc.align-justify-right {
    margin-left: 0;
    width: 100%;
    padding-left: 0; }
  h1.property-title-h {
    font-size: 24px; }
  .tabs-title {
    float: left;
    display: block;
    width: 100%;
    text-align: left; }
  .tabs-title > a[aria-selected='true'] {
    text-align: left; }
  .latest-properties-slider .owl-dots {
    position: absolute;
    bottom: -35px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    text-align: center; }
    .latest-properties-slider .owl-dots button.owl-dot {
      background: #02540b;
      color: beige;
      border: none;
      padding: 0 !important;
      font: 20px;
      border: 1px solid;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      opacity: 0.2;
      margin: 0 3px; }
      .latest-properties-slider .owl-dots button.owl-dot.active {
        opacity: 1;
        width: 16px;
        height: 16px; }
  section#reviews-section {
    padding-bottom: 50px; }
  #slideshow .slide img {
    height: 75vh; } }

section#listings {
  padding: 75px 0; }

.search-sidebar-title-h {
  display: block;
  font-weight: 700;
  font-size: 24px;
  margin-bottom: 5px;
  line-height: 1.4;
  color: #2a2a2a;
  font-family: "Playfair Display", serif; }

.search-sidebar-p {
  font-size: 15px;
  margin: 12px 0 15px; }

.sidebar-help {
  position: relative; }
  .sidebar-help .interested {
    padding: 10px 0;
    letter-spacing: 0;
    border-radius: 3px;
    display: block;
    font-weight: 700;
    font-size: 16px;
    margin-bottom: 5px;
    line-height: 1.4;
    color: #2a2a2a;
    font-family: "Playfair Display", serif; }
  .sidebar-help .make-offer-a,
  .sidebar-help .message-seller-a,
  .sidebar-help .request-viewing-a {
    font-size: 16px;
    font-family: "Lato", sans-serif;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 26px;
    display: block;
    text-align: center;
    width: 100%;
    background-color: #f2f2f2;
    color: #919090;
    margin-bottom: 12px;
    border-radius: 2px;
    padding: 12px 10px;
    transition: 0.3s all ease; }
    .sidebar-help .make-offer-a:hover,
    .sidebar-help .message-seller-a:hover,
    .sidebar-help .request-viewing-a:hover {
      background-color: #02540b;
      color: #fff; }
    .sidebar-help .make-offer-a i,
    .sidebar-help .message-seller-a i,
    .sidebar-help .request-viewing-a i {
      margin-right: 4px; }
  .sidebar-help .request-viewing-a {
    background-color: #02540b;
    color: #fff; }
    .sidebar-help .request-viewing-a:hover {
      background-color: #02540b;
      color: #fff; }

.sidebar-search-default-title-h {
  font-size: 15px;
  text-transform: uppercase;
  color: #02540b;
  font-family: "Lato", sans-serif;
  font-weight: 600;
  letter-spacing: 0;
  margin-bottom: 15px;
  margin-top: 15px; }
  .sidebar-search-default-title-h i {
    margin-right: 4px; }

.find-properties-button .button {
  font-size: 16px;
  font-family: "Lato", sans-serif;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 26px;
  display: block;
  text-align: center;
  width: 100%;
  background-color: #f2f2f2;
  color: #919090;
  margin: auto auto 12px;
  border-radius: 2px;
  padding: 12px 60px;
  transition: 0.3s all ease; }
  .find-properties-button .button i.fas.fa-search {
    margin-right: 4px;
    font-size: 11px;
    vertical-align: middle; }
  .find-properties-button .button:hover {
    background-color: #02540b;
    color: #fff; }

.align-justify-left {
  margin-left: 0 !important; }

.align-justify-right {
  margin-right: 0 !important; }

.margin-bottom {
  margin-bottom: 0 !important; }

.sidebar-help .right-content-mid {
  margin-top: 24px; }

.listing-item {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #e9e9e9; }

.listing-left-wrap {
  position: relative; }
  .listing-left-wrap .listing-property-thumb-img {
    height: 270px;
    width: 100%;
    object-fit: cover;
    object-position: center; }
  .listing-left-wrap p.price {
    text-align: center;
    line-height: 1;
    margin-bottom: 0;
    background-color: #2a2a2a;
    font-size: 17px;
    font-family: "Playfair Display", serif;
    color: #ffffff;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 20px; }
  .listing-left-wrap p.sold-stc {
    position: absolute;
    right: 10px;
    top: 10px;
    background: #02540b;
    font-size: 10px;
    width: 55px;
    height: 55px;
    border-radius: 50%;
    line-height: 1.25;
    padding: 14px 5px;
    text-align: center;
    margin: 0;
    color: #fff;
    text-transform: uppercase;
    font-family: "Playfair Display", serif; }

.listing-desc {
  padding-left: 30px; }
  .listing-desc .archive-listing-h {
    font-size: 30px;
    line-height: 1.25;
    color: #000;
    font-family: "Playfair Display", serif;
    margin-bottom: 0; }
  .listing-desc .archive-listing-address {
    font-size: 15px;
    text-transform: uppercase;
    color: #02540b;
    font-family: "Lato", sans-serif;
    font-weight: 600;
    letter-spacing: 2px;
    margin: 10px 0 20px; }
  .listing-desc p.listing-short-desc {
    font-size: 15px; }

.listing-buttons-wrap {
  position: relative; }
  .listing-buttons-wrap .button.button-details {
    border: 1px solid #02540b;
    color: #ffffff;
    background: #02540b;
    border-radius: 0;
    margin-right: 10px;
    padding: 15px 30px; }
    .listing-buttons-wrap .button.button-details:hover {
      background-color: #fff;
      color: #02540b; }
  .listing-buttons-wrap .button.button-viewing {
    border: 1px solid #02540b;
    color: #ffffff;
    background: #02540b;
    border-radius: 0;
    padding: 15px 30px; }
    .listing-buttons-wrap .button.button-viewing:hover {
      background-color: #fff;
      color: #02540b; }

.property-auctions thead th {
  font-size: 12px;
  text-transform: uppercase;
  color: #02540b;
  font-family: "Lato", sans-serif;
  font-weight: 900;
  letter-spacing: 0;
  margin-bottom: 7px; }
  .property-auctions thead th i {
    margin-right: 4px; }

.property-auctions thead {
  background: #fafafa;
  color: #02540b; }

.reviews-content h2.reviews-h-content {
  display: block;
  font-weight: 700;
  font-size: 28px;
  margin-bottom: 8px;
  line-height: 1.4;
  color: #2a2a2a;
  font-family: "Playfair Display", serif; }

.reviews-content .title-section {
  width: 90%;
  margin: auto;
  text-align: center;
  margin-bottom: 50px; }

.contact-a-wrap {
  margin: 30px 0; }
  .contact-a-wrap .contact-a {
    color: #02540b;
    font-family: "Lato", sans-serif;
    display: block;
    font-size: 30px;
    font-weight: 900;
    margin-bottom: 0;
    line-height: 44px; }
    .contact-a-wrap .contact-a:hover {
      color: #012204; }

a {
  color: #02540b; }
  a:hover {
    color: #012204; }

input[type="text"],
textarea {
  font-size: 15px;
  font-weight: 700;
  color: #000;
  border: 1px solid #e9e9e9;
  margin-bottom: 20px;
  -webkit-appearance: none;
  box-shadow: none;
  padding: 10px;
  line-height: 24px;
  font-family: "Lato", sans-serif; }
  input[type="text"]:focus,
  textarea:focus {
    outline: none;
    border: 1px solid #e9e9e9;
    background-color: #fff;
    box-shadow: 0 0 0 #cacaca;
    transition: box-shadow 0.5s, border-color 0.25s ease-in-out; }

.input-group {
  display: block;
  font-size: 12px;
  text-transform: uppercase;
  color: #02540b;
  font-family: "Lato", sans-serif;
  font-weight: 900;
  letter-spacing: 0;
  margin-bottom: 7px; }

.error-message {
  color: red;
  font-weight: 700; }

.button {
  background-color: #02540b;
  color: #fefefe;
  font-family: "Lato", sans-serif;
  text-transform: unset; }
  .button:hover {
    background-color: #012204; }

.whoops {
  font-size: 28px;
  margin-top: 30px;
  color: #02540b; }

.contact-form {
  margin-top: 100px; }
  .contact-form .input-group {
    display: block;
    font-size: 18px;
    line-height: 1.25;
    text-transform: none;
    color: #000;
    font-family: "Playfair Display", serif;
    font-weight: 400;
    margin-bottom: 20px; }
    .contact-form .input-group textarea,
    .contact-form .input-group input {
      margin-top: 10px;
      font-weight: 400;
      padding: 12px;
      height: auto;
      line-height: 1; }

@media screen and (max-width: 650px) {
  .footer-logo {
    text-align: center; }
  .sidebar-help a.message-seller-a {
    width: 48%;
    margin-right: 1%;
    float: left; }
  .sidebar-help a.make-offer-a {
    width: 48%;
    margin-left: 1%;
    float: right; }
  .mobile-half-width {
    width: 48% !important; }
    .mobile-half-width.tablet-align-justify-left {
      margin-left: 1% !important; }
    .mobile-half-width.tablet-align-justify-right {
      margin-right: 1% !important; }
  p.results-count {
    width: calc(50% - 0.625rem);
    margin: 0 10px; }
  footer .grid-x .cell.auto .footer-menu ul {
    float: none;
    text-align: center; }
  .footer-icons {
    text-align: center; }
  .footer-phone {
    text-align: center; }
  footer ul {
    margin: 0;
    float: none;
    margin-top: 21px;
    text-align: center; }
  #slideshow .slider-overlay .grid-x h1 strong {
    font-size: 25px; }
  #slideshow .slider-overlay h1 {
    font-size: 13px; }
  #slideshow .slider-overlay p {
    margin-bottom: 19px;
    font-size: 16px;
    line-height: 19px; }
  #cookie-notice p {
    margin-bottom: 10px;
    color: #fff;
    font-size: 12px;
    line-height: 15px; }
  #cookie-notice p {
    margin-bottom: 10px;
    color: #fff;
    font-size: 12px;
    line-height: 15px; }
  #slideshow .slider-overlay a {
    padding: 10px 35px;
    border: 2px solid #e5d47f;
    margin: 0 0 10px;
    padding: 7px 31px;
    font-size: 12px; }
  #slideshow .slide img {
    height: 80vh !important;
    filter: brightness(0.85); }
  h3 {
    font-size: 18px;
    letter-spacing: 0;
    margin-bottom: 2px; }
  h2 {
    font-size: 20px;
    margin-bottom: 20px; }
  .contact-a-wrap .contact-a {
    font-size: 24px;
    line-height: 32px; }
  section#home-search h2 {
    margin-bottom: 24px;
    font-size: 32px; }
  section#home-search {
    padding: 10px 0 40px; }
  p.latest-property-price {
    font-size: 18px;
    margin-top: 20px; }
  .eq-height-cols h2 {
    font-size: 22px; }
  .title-section .reviews-h {
    font-size: 24px; }
  #who-we-are-home .text-block a {
    font-size: 12px;
    line-height: 14px; }
  section#reviews-section {
    margin-bottom: 0;
    background-color: #fefcfc;
    padding: 50px 0; }
  .review-slider .eq-height-cols:after {
    content: none; } }

.custom-dropdown select:focus {
  outline: none;
  border-radius: 0;
  box-shadow: none; }

span {
  margin: auto; }
  span .search-select {
    max-width: 430px;
    height: 60px;
    background-color: #f2f2f2;
    border: none;
    background-origin: content-box;
    background-position: right 0 center;
    background-repeat: no-repeat;
    background-size: 15px 9px;
    padding-right: 1.5rem;
    transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
    -webkit-appearance: none;
    apearance: none;
    text-transform: uppercase;
    padding-left: 21px;
    font-size: 13px;
    font-family: "Lato", sans-serif;
    color: #535353; }

p.email-footer a {
  color: #fff; }
  p.email-footer a i {
    margin-right: 10px; }

.content-main {
  padding-bottom: 100px; }
  @media screen and (max-width: 1025px) {
    .content-main {
      padding-bottom: 50px; } }

@media screen and (max-width: 650px) {
  #lettings-home .small-order-3 {
    display: none; }
  .right-content-propery.grid-x.grid-margin-x {
    display: inline-block;
    width: inherit;
    margin: auto;
    min-width: none; }
    .right-content-propery.grid-x.grid-margin-x .right-content-mid,
    .right-content-propery.grid-x.grid-margin-x .right-content-top {
      float: left; }
    .right-content-propery.grid-x.grid-margin-x .right-content-top {
      padding-right: 10px; }
    .right-content-propery.grid-x.grid-margin-x .right-content-mid {
      padding-left: 10px; }
    .right-content-propery.grid-x.grid-margin-x .right-content-bottom {
      float: left;
      clear: both;
      margin-top: 10px; }
  .right-content-top .make-offer-a,
  .right-content-top .message-seller-a,
  .right-content-top .request-viewing-a {
    font-size: 12px;
    padding: 9px 1px; }
  .right-content-mid .need-help-a,
  .right-content-top .interested {
    font-size: 13px;
    letter-spacing: 0;
    line-height: 19px; }
  .right-content-mid .need-help-sub-a {
    font-size: 13px;
    letter-spacing: 0;
    line-height: 16px;
    margin-bottom: 17px; }
  .tabs-title {
    float: left;
    display: block;
    width: 100%;
    text-align: left; }
  .tabs-title > a[aria-selected='true'] {
    text-align: left; }
  .tabs-panel {
    padding: 20px; }
  .property-left-content .property-title-h {
    font-size: 24px; }
  img.footer-logo-img {
    max-width: 163px; }
  footer .grid-x .cell.auto .footer-menu ul {
    text-align: center; }
    footer .grid-x .cell.auto .footer-menu ul li {
      padding: 0 6px; }
      footer .grid-x .cell.auto .footer-menu ul li:first-of-type {
        padding-left: 6px; } }

.property-images #sync1.owl-carousel .owl-nav {
  position: absolute;
  top: 50%;
  left: 3%;
  right: 3%;
  text-align: center;
  transform: translateY(-50%); }
  .property-images #sync1.owl-carousel .owl-nav .owl-prev {
    left: 0;
    position: absolute; }
    .property-images #sync1.owl-carousel .owl-nav .owl-prev i {
      color: #fff;
      font-size: 25px; }
  .property-images #sync1.owl-carousel .owl-nav .owl-next {
    right: 0;
    position: absolute; }
    .property-images #sync1.owl-carousel .owl-nav .owl-next i {
      color: #fff;
      font-size: 25px; }

.property-images #sync1.owl-carousel .owl-item img {
  display: block;
  width: 100%;
  height: 550px;
  object-fit: cover; }

.property-images #sync2.owl-carousel {
  margin-bottom: 25px; }
  .property-images #sync2.owl-carousel .active.current {
    border-bottom: 2px solid #02540b;
    padding-bottom: 9px; }
  .property-images #sync2.owl-carousel .owl-item img {
    height: 150px;
    object-fit: cover; }

.right-content-top h1 {
  font-weight: 700;
  font-size: 30px;
  margin-bottom: 15px;
  line-height: 1.4;
  color: #2a2a2a;
  margin-top: 0; }

.right-content-top h3 {
  margin-bottom: 15px;
  font-size: 12px;
  text-transform: uppercase;
  color: #02540b;
  font-family: "Lato", sans-serif;
  font-weight: 900;
  letter-spacing: 2px; }

.right-content-top p.display-price {
  background-color: #004d08;
  color: #fff;
  display: inline-block;
  padding: 10px 40px;
  font-size: 16px;
  font-family: "Lato", sans-serif;
  font-weight: 600;
  letter-spacing: 2px;
  border-radius: 3px;
  line-height: 26px; }

/* PRINT CSS */
@media print {
  header,
  #slideshow,
  footer {
    display: none; }
  #listings {
    padding: 0; }
  #listings .home-search-row {
    display: none; }
  #listings .listing-page-sort {
    width: 100%; }
    #listings .listing-page-sort .sort-row {
      display: none; }
  #listings .listing-bottom {
    display: none; }
  .sidebar-help {
    display: none; } }

/* Module Stylesheets - Comment these out if not using the module */
/* Case Study Module Custom Styles */
.case-card-cont {
  margin-bottom: 2rem; }

.case-card {
  border: 1px solid #CBCBCB;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  transition: 0.3s; }

.case-card .case-img-wrapper {
  overflow: hidden; }

.case-card img {
  width: 100%;
  height: auto;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  transition: 0.5s;
  overflow: hidden; }

.case-card .case-label a {
  padding: 1rem;
  text-align: center;
  display: block;
  color: #1f1f1f;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  transition: 0.3s; }

.case-card:hover {
  border: 1px solid #fff;
  background-color: #02540b; }

.case-card:hover img {
  transform: scale(1.1);
  opacity: 0.3s; }

.case-card:hover .case-label a {
  background-color: #02540b;
  color: #fff; }

#news_case_big {
  display: flex;
  flex-flow: row wrap; }

#news_case_big #mainimage img {
  width: 100%; }

/* Gallery Module Custom Styles */
.gallery-main .owl-next {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  font-family: monospace;
  font-size: 27px;
  display: block;
  height: 30px;
  width: 30px;
  background-color: #02540b;
  text-align: center;
  line-height: 28px;
  border-radius: 99999px;
  color: #fff; }

.gallery-main .owl-prev {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  font-family: monospace;
  font-size: 27px;
  display: block;
  height: 30px;
  width: 30px;
  background-color: #02540b;
  text-align: center;
  line-height: 28px;
  border-radius: 99999px;
  color: #fff; }

.gallery-main {
  position: relative;
  padding: 0px 6%;
  margin: 0 auto 20px; }

/* News Module Custom Styles */
.news-card-cont {
  display: flex; }

.news-articles {
  display: flex;
  width: 100%;
  flex-flow: row wrap; }

.news-card-cont.column.small-12.medium-6 {
  margin-bottom: 1.8rem; }

.news-card {
  box-sizing: border-box;
  border: 1px solid #CBCBCB;
  padding: 2rem; }

/* Products Module Custom Styles */
div#breadcrumbs {
  margin-bottom: 1.5rem; }

.product-card-cont {
  margin-bottom: 2rem; }

.product-card {
  border: 1px solid #CBCBCB;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  transition: 0.3s;
  overflow: hidden; }

.product-card > a {
  overflow: hidden;
  display: block; }

.product-card .product-img-wrapper {
  overflow: hidden; }

.product-card img {
  width: 100%;
  height: auto;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  transition: 0.5s;
  overflow: hidden; }

.product-card .product-label a {
  padding: 1rem;
  text-align: center;
  display: block;
  color: #1f1f1f;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  transition: 0.3s; }

.product-card:hover {
  border: 1px solid #fff;
  background-color: #02540b; }

.product-card:hover img {
  transform: scale(1.1);
  opacity: 0.3s; }

.product-card:hover .product-label a {
  background-color: #02540b;
  color: #fff; }

ul.thumbs {
  list-style-type: none;
  margin: 0px; }

.product-left .owl-dots {
  text-align: center; }

.product-left .owl-dot {
  height: 8px;
  width: 8px;
  background-color: rgba(34, 39, 40, 0.4);
  border-radius: 50%;
  display: inline-block;
  margin-right: 10px;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  transition: 0.3s; }

.product-left .owl-dot:hover, .product-left .owl-dot.active {
  background-color: #02540b; }

.product-left .owl-pagination {
  text-align: center; }
